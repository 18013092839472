.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

input,label{
  display: block;
  padding-bottom: 0.4rem;
  padding-left: 0.2rem;
}

span{
  font-weight: 600;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text{
  color: white !important;
}

.p-datatable .p-datatable-thead > tr > th{
  background: black !important;
  color: white !important;
}
.p-datatable .p-datatable-tbody > tr > td{
  text-align: center !important;
}

.p-datatable .p-datatable-thead > tr > th{
  text-align: center;
}

.p-datatable .p-column-header-content{
  justify-content: center;
}




