
@media only screen and (max-device-width:767px) {
    .onboarding-form-margin{
        padding-left: 0rem;
    }
    .dashboard-margin {
        padding: 1rem !important;
        .p-tabview-nav-container{
            font-weight: 800;
            font-size: 0.8rem ;
            @media only screen and (max-device-width:376px){
                font-size: 0.6rem !important;
            }
        }
        .campaign-details{
            display: flex;
            flex-direction: column;
        }
        .campaign-span{
            padding-bottom: 0.5rem;
        }
    }
    .react-pdf__Page__canvas{
        padding-left: 8rem;
        padding-right: 8rem;
    }

    .form-flex{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;
        .flex-container{
            width: 100%;
        }

        .left-padding{
            padding-left: 0rem;
        }
        .right-padding{
            padding-right: 0rem;
        }
    }

    .welcome-text{
        padding-left: 1rem;
    }

    .influencer-campaign{
        padding: 1rem !important;
        min-height: 70vh;
        overflow:scroll;
        .p-tabview-nav-container{
            font-size: 0.9rem;
        }
        .campaign-details{
            display: flex;
            flex-direction: column;
        }
        .campaign-span{
            padding-bottom: 0.5rem;
        }
    }

    .upload, .start-creating{
       margin-right: 35% !important;
    }
    .p-datatable  .p-datatable-tbody > tr > td:not(:last-child) {
        border: 0 none !important;
    }

    .modal-width {
        width: 80%;
    }
    .p-datatable .p-datatable-tbody > tr > td{
       
        text-align: left !important;
        border: 1px solid rgb(6, 27, 30) !important;
        border-width: 0 0 1px 0 !important;
        padding: 1rem 1rem !important;
        
    }
    .forgot-password{
        padding: 5rem;
        height: 80vh;
        display: flex;
        justify-content: center;
    }

    .password-field{
        margin-top: 1rem !important;
        border-radius: 0.4rem !important;
        width: 18rem !important;
    }

    .password-error{
        color: red;
        margin-top: 1rem;
        font-size: 12px;
    }
}

@media only screen and (min-device-width:767px) {
    .onboarding-form-margin{
        padding-left: 8rem;
    }
    .dashboard-margin {
        padding: 2rem !important;
        .campaign-details{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
    }

    .modal-width {
        width: 50%;
    }

    .form-flex{
        display: flex;
        flex-direction: row;
        height: 50%;
        margin-left: 2.5rem;
        flex-wrap: wrap;
        .flex-container{
            width: 50%;
        }

        .left-padding{
            padding-left: 10rem;
        }
        .right-padding{
            padding-right: 10rem;
        }
    }

    .welcome-text{
        padding-left: 13rem;
    }

    .influencer-campaign{
        padding: 1rem !important;
        min-height: 70vh;
        overflow:scroll;
        .p-tabview-nav-container{
            font-size: 0.9rem;
        }
        .campaign-details{
            display: flex;
            flex-direction: column;
        }
        .campaign-span{
            padding-bottom: 0.5rem;
        }
    }

    .forgot-password{
        padding: 5rem;
        height: 80vh;
        display: flex;
        justify-content: center;
    }

    .password-field{
        margin-top: 1rem !important;
        border-radius: 0.4rem !important;
        width: 30rem !important;
    }

    .password-error{
        color: red;
        margin-top: 1rem;
        font-size: 12px;
    }
}

