@media only screen and (max-device-width:767px) {
    .main {
        background-image: url('../auth/bg-mobile.png');
        background-size: cover ;
        min-height: 80vh;
        background-repeat: "no-repeat" ;
    }

    .login-card {
        width: 95% !important;
        margin-top: 3rem !important;
    }

    .forgot-password-page{
        height:40vh;
        display: flex;
        justify-content: center;
        flex-direction: row;
        margin: 1rem;
        margin-top: 3rem;
    }

    .field-input{
        width: 17rem;
        margin:0.5rem !important;
    }
}
// style={{ backgroundImage: `url(${background})`, height: "100vh", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}
@media only screen and (min-device-width:767px) {
    .main {
        background-image: url('../auth/background.jpg');
        background-size: cover;
        height: 100vh;
        background-repeat: no-repeat;
    }


    .forgot-password-page{
        height:60vh;
        display: flex;
        justify-content: center;
        flex-direction: row;
        margin-top: 10rem;

    }

    .field-input{
        width: 20rem;
        margin:0.5rem !important;
    }
}
.login-card {
    background: white;
    border-radius: 20px;
    width: 60%;
    display: block;
    margin-left: auto;
    margin-top: 10rem;
    margin-right: auto;
    padding: 1rem;
    @media only screen and (max-width: 720px) {
        width: 90%;
    }

}