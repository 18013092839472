
@media only screen and (max-device-width: 767px ) {
    footer {
        position:relative;
        bottom:0;
        left:0;
        z-index: 3;
        width: 100%;
        padding: 50px 0;
        background-color:black;
        // height: 50vh;
    }
    
    
    .single-col {
        flex: 0 50%;
        padding-left: 2rem;
        border: 0;
        outline: 0;
        font-family: Muli,sans-serif;
        -webkit-font-smoothing: antialiased;
        font-size: 18px;
        font-weight: 700;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.86;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
        margin-right: 0;
    }
    
    .footer-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    
    .footer-menu {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .footer-menu .row-heading {
        font-size: 18px;
        font-weight: 700;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.86;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
        margin: 0 0 20px;
    }
    .max1170 {
        max-width: 90%;
        margin: auto;
    }
    
    .footer-menu ul li a {
        font-size: 14px;
        font-weight: 400;
        font-style: normal;
        font-stretch: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
        display: block;
        margin-bottom: 9px;
        opacity: .7;
    }
    
    ul li {
        list-style: none;
    }
    
    a {
        color: #000;
        outline-style: none;    
        margin: 0;
        padding: 0;
        text-decoration: none;
        
    }
    
    .footer-social {
        display: flex;
        flex-direction: column;

    }
    
    .footer-social .social-media {
        padding-right:7rem;
        flex-grow: 1;
    }
    
    .footer-social .social-media ul li, .footer-social .social-media ul li a {
        display: inline-block;
        vertical-align: middle;
        height: 40px;
        width: 40px;
    }
    .footer-social .social-media li a img {
        width: 100%;
        height: auto;
        padding: 2px;
    }
    
    .footer-social .social-media ul {
        text-align: right;
    }
    
    .copyright {
        font-size: 14px;
        color: #fff;
        text-align: right;
        opacity: .7;
    }
    
    ul{
        padding-inline-start: 0px;
    }
    
}

@media only screen and (min-device-width : 767px) {
    footer {
        position: relative;
        z-index: 3;
        padding: 100px 0;
        background-color: #000;
        height: 50vh;
    }
    
    
    .single-col {
        padding-left: 0rem;
        border: 0;
        outline: 0;
        font-family: Muli,sans-serif;
        -webkit-font-smoothing: antialiased;
        font-size: 18px;
        font-weight: 700;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.86;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
        margin: 0 0 20px;
    }
    
    .footer-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    
    .footer-menu .row-heading {
        font-size: 18px;
        font-weight: 700;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.86;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
        margin: 0 0 20px;
    }
    .max1170 {
        max-width: 90%;
        margin: auto;
    }
    
    .footer-menu ul li a {
        font-size: 14px;
        font-weight: 400;
        font-style: normal;
        font-stretch: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
        display: block;
        margin-bottom: 9px;
        opacity: .7;
    }
    
    ul li {
        list-style: none;
    }
    
    a {
        color: #000;
        outline-style: none;    
        margin: 0;
        padding: 0;
        font-size: 100%;
        text-decoration: none;
        
    }
    
    .footer-social {
        display: flex;
        flex-direction: column;
    }
    
    .footer-social .social-media {
        flex-grow: 1;
    }
    
    .footer-social .social-media ul li, .footer-social .social-media ul li a {
        display: inline-block;
        vertical-align: middle;
        height: 40px;
        width: 40px;
    }
    .footer-social .social-media li a img {
        width: 100%;
        height: auto;
        padding: 2px;
    }
    
    .footer-social .social-media ul {
        text-align: right;
    }
    
    .copyright {
        font-size: 14px;
        color: #fff;
        text-align: right;
        opacity: .7;
    }
    
    ul{
        padding-inline-start: 0px;
    }
    
}

