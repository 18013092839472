.search{
    width: 100%;
    margin-top: 10rem !important;
}

.search_button{
    margin-top: 10rem !important;
    margin-left: 0.2rem !important;
}

.admin-filters{
    margin-top: 4rem;
}

.p-column-filter-menu {
    margin-left: 1rem !important;
}